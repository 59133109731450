// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

var options = [
  {
    name: "Home",
    href: "/"
  },
  {
    name: "Jobs",
    href: "/jobs"
  },
  {
    name: "GitHub",
    href: "/github"
  }
];

function Header$MenuButton(Props) {
  var name = Props.name;
  var href = Props.href;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var path = "/" + $$String.concat("/", url.path);
  var is_selected = href === "/" ? path === "/" : path.startsWith(href);
  return React.createElement("li", undefined, React.createElement("a", {
                  className: "nav-menu-option " + (
                    is_selected ? "selected" : ""
                  ),
                  onClick: (function (param) {
                      return RescriptReactRouter.push(href);
                    })
                }, name));
}

var MenuButton = {
  make: Header$MenuButton
};

function Header(Props) {
  return React.createElement("nav", undefined, React.createElement("img", {
                  alt: "",
                  height: "29",
                  src: "/images/dashboard-logo.png"
                }), React.createElement("div", {
                  className: "site-name"
                }, "ocaml-multicore-ci"), React.createElement("ul", undefined, Belt_Array.map(options, (function (option) {
                        return React.createElement(Header$MenuButton, {
                                    name: option.name,
                                    href: option.href,
                                    key: option.name
                                  });
                      }))), React.createElement("ul", {
                  className: "right"
                }));
}

var make = Header;

export {
  options ,
  MenuButton ,
  make ,
  
}
/* react Not a pure module */
