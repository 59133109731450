// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

function useInterval(f, ms) {
  var timeout = React.useRef(null);
  React.useEffect((function () {
          Curry._1(f, undefined);
          timeout.current = setInterval(f, ms);
          return (function (param) {
                    Js_null_undefined.bind(timeout.current, (function (i) {
                            clearInterval(i);
                            
                          }));
                    
                  });
        }), []);
  
}

export {
  useInterval ,
  
}
/* react Not a pure module */
