// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as URL$WebApp from "../utils/URL.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as Belt_HashMapString from "bs-platform/lib/es6/belt_HashMapString.js";
import * as Belt_HashSetString from "bs-platform/lib/es6/belt_HashSetString.js";
import * as ConfigHooks$WebApp from "../ConfigHooks.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as OutcomeDisplay$WebApp from "../components/jobs/OutcomeDisplay.bs.js";
import * as URLSearchParams$WebApp from "../utils/URLSearchParams.bs.js";
import * as RefreshBarWithQuery$WebApp from "../components/navigation/RefreshBarWithQuery.bs.js";

var Raw = {};

var query = "query   {\njobs  {\nowner  \nname  \nhash  \njob_id  \nvariant  \noutcome  \nerror  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.jobs;
  return {
          jobs: value$1.map(function (value) {
                var value$1 = value.outcome;
                var tmp;
                switch (value$1) {
                  case "Aborted" :
                      tmp = "Aborted";
                      break;
                  case "Active" :
                      tmp = "Active";
                      break;
                  case "Failed" :
                      tmp = "Failed";
                      break;
                  case "NotStarted" :
                      tmp = "NotStarted";
                      break;
                  case "Passed" :
                      tmp = "Passed";
                      break;
                  default:
                    tmp = {
                      NAME: "FutureAddedValue",
                      VAL: value$1
                    };
                }
                var value$2 = value.error;
                return {
                        owner: value.owner,
                        name: value.name,
                        hash: value.hash,
                        job_id: value.job_id,
                        variant: value.variant,
                        outcome: tmp,
                        error: !(value$2 == null) ? value$2 : undefined
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.jobs;
  var jobs = value$1.map(function (value) {
        var value$1 = value.error;
        var error = value$1 !== undefined ? value$1 : null;
        var value$2 = value.outcome;
        var outcome = typeof value$2 === "string" ? (
            value$2 === "Aborted" ? "Aborted" : (
                value$2 === "NotStarted" ? "NotStarted" : (
                    value$2 === "Failed" ? "Failed" : (
                        value$2 === "Passed" ? "Passed" : "Active"
                      )
                  )
              )
          ) : value$2.VAL;
        var value$3 = value.variant;
        var value$4 = value.job_id;
        var value$5 = value.hash;
        var value$6 = value.name;
        var value$7 = value.owner;
        return {
                owner: value$7,
                name: value$6,
                hash: value$5,
                job_id: value$4,
                variant: value$3,
                outcome: outcome,
                error: error
              };
      });
  return {
          jobs: jobs
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var GetAllJobs = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

function GridView$JobRow(Props) {
  var repo = Props.repo;
  var jobs = Props.jobs;
  var variants = Props.variants;
  var adminServiceUri = ConfigHooks$WebApp.useAdminServiceUri(undefined);
  return React.createElement("tr", undefined, React.createElement("td", undefined, repo), Belt_Array.map(variants, (function (variant) {
                    var job = Belt_HashMapString.get(jobs, variant);
                    if (job === undefined) {
                      return React.createElement("td", {
                                  key: variant
                                }, "\0");
                    }
                    var onClick = function (ev) {
                      ev.stopPropagation();
                      ev.preventDefault();
                      var href = adminServiceUri + "/job/" + job.job_id;
                      window.open(href);
                      
                    };
                    return React.createElement("td", {
                                key: variant,
                                className: "clickable",
                                onClick: onClick
                              }, React.createElement(OutcomeDisplay$WebApp.make, {
                                    outcome: job.outcome,
                                    err: job.error
                                  }));
                  })));
}

var JobRow = {
  make: GridView$JobRow
};

function make_jobs_by_repo(isIgnoreActive, jobs) {
  var ignoredStates = [
    "Aborted",
    "NotStarted",
    "Undefined"
  ];
  if (isIgnoreActive) {
    ignoredStates.push("Active");
  }
  var result = Belt_HashMapString.make(10);
  Belt_Array.forEach(jobs, (function (job) {
          if (!(job.variant !== "(analysis)" && !ignoredStates.includes(job.outcome))) {
            return ;
          }
          var repo = job.owner + "/" + job.name;
          var h = Belt_HashMapString.get(result, repo);
          var d = h !== undefined ? Caml_option.valFromOption(h) : Belt_HashMapString.make(10);
          Belt_HashMapString.set(d, job.variant, job);
          return Belt_HashMapString.set(result, repo, d);
        }));
  return result;
}

function make_variants(jobs) {
  var result = Belt_HashSetString.make(10);
  Belt_Array.forEach(jobs, (function (job) {
          if (job.variant !== "(analysis)") {
            return Belt_HashSetString.add(result, job.variant);
          }
          
        }));
  return Belt_HashSetString.toArray(result).sort();
}

function GridView$Content(Props) {
  var jobs = Props.jobs;
  var isIgnoreActive = Props.isIgnoreActive;
  var jobs$1 = jobs.filter(function (job) {
        return $$String.compare(job.job_id, "2021-09-26") > 0;
      });
  var variants = make_variants(jobs$1);
  var jobs_by_repo = make_jobs_by_repo(isIgnoreActive, jobs$1);
  return React.createElement("table", {
              className: "table cell-select"
            }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, "Repo"), Belt_Array.map(variants, (function (variant) {
                            return React.createElement("th", {
                                        key: variant
                                      }, variant);
                          })))), React.createElement("tbody", undefined, Belt_Array.map(Belt_HashMapString.toArray(jobs_by_repo).sort(function (param, param$1) {
                          return $$String.compare(param[0], param$1[0]);
                        }), (function (param) {
                        var repo = param[0];
                        return React.createElement(GridView$JobRow, {
                                    repo: repo,
                                    jobs: param[1],
                                    variants: variants,
                                    key: repo
                                  });
                      }))));
}

var Content = {
  make_jobs_by_repo: make_jobs_by_repo,
  make_variants: make_variants,
  make: GridView$Content
};

function GridView(Props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var search = new URLSearchParams(url.search);
  var isIgnoreActive = URLSearchParams$WebApp.getBoolean(search, "ignore-active");
  var match = RefreshBarWithQuery$WebApp.useWithQuery({
        Raw: Raw,
        query: query,
        parse: parse,
        serializeVariables: serializeVariables,
        variablesToJson: (function (prim) {
            return prim;
          })
      });
  var response = match[0];
  var onChangeIgnoreActive = function (param) {
    URLSearchParams$WebApp.setBoolean(search, "ignore-active", !isIgnoreActive);
    return RescriptReactRouter.replace(URL$WebApp.join(url.path, search));
  };
  var tmp;
  var exit = 0;
  if (typeof response === "number") {
    tmp = response === /* Fetching */0 ? "Loading" : "Not Found";
  } else {
    switch (response.TAG | 0) {
      case /* Data */0 :
      case /* PartialData */1 :
          exit = 1;
          break;
      case /* Error */2 :
          tmp = response._0.message;
          break;
      
    }
  }
  if (exit === 1) {
    tmp = React.createElement(GridView$Content, {
          jobs: response._0.jobs,
          isIgnoreActive: isIgnoreActive
        });
  }
  return React.createElement("main", undefined, React.createElement("div", {
                  className: "p-8 flex-col"
                }, match[1], React.createElement("div", {
                      className: "flex-row"
                    }, React.createElement(Core.FormControlLabel, {
                          control: React.createElement(Core.Checkbox, {
                                checked: isIgnoreActive,
                                onChange: onChangeIgnoreActive
                              }),
                          label: "Ignore active jobs"
                        })), tmp));
}

var make = GridView;

export {
  GetAllJobs ,
  JobRow ,
  Content ,
  make ,
  
}
/* react Not a pure module */
