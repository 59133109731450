// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as URLSearchParams$WebApp from "./URLSearchParams.bs.js";

function join(path, q) {
  var joinedPath = Belt_List.toArray(path).join("/");
  return "/" + joinedPath + URLSearchParams$WebApp.toStringQuestionMark(q);
}

export {
  join ,
  
}
/* No side effect */
