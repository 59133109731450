// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as ConfigHooks$WebApp from "../ConfigHooks.bs.js";
import * as OutcomeDisplay$WebApp from "../components/jobs/OutcomeDisplay.bs.js";
import * as RefreshBarWithQuery$WebApp from "../components/navigation/RefreshBarWithQuery.bs.js";

var Raw = {};

var query = "query   {\njobs  {\nowner  \nname  \nhash  \njob_id  \nvariant  \noutcome  \nerror  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.jobs;
  return {
          jobs: value$1.map(function (value) {
                var value$1 = value.outcome;
                var tmp;
                switch (value$1) {
                  case "Aborted" :
                      tmp = "Aborted";
                      break;
                  case "Active" :
                      tmp = "Active";
                      break;
                  case "Failed" :
                      tmp = "Failed";
                      break;
                  case "NotStarted" :
                      tmp = "NotStarted";
                      break;
                  case "Passed" :
                      tmp = "Passed";
                      break;
                  default:
                    tmp = {
                      NAME: "FutureAddedValue",
                      VAL: value$1
                    };
                }
                var value$2 = value.error;
                return {
                        owner: value.owner,
                        name: value.name,
                        hash: value.hash,
                        job_id: value.job_id,
                        variant: value.variant,
                        outcome: tmp,
                        error: !(value$2 == null) ? value$2 : undefined
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.jobs;
  var jobs = value$1.map(function (value) {
        var value$1 = value.error;
        var error = value$1 !== undefined ? value$1 : null;
        var value$2 = value.outcome;
        var outcome = typeof value$2 === "string" ? (
            value$2 === "Aborted" ? "Aborted" : (
                value$2 === "NotStarted" ? "NotStarted" : (
                    value$2 === "Failed" ? "Failed" : (
                        value$2 === "Passed" ? "Passed" : "Active"
                      )
                  )
              )
          ) : value$2.VAL;
        var value$3 = value.variant;
        var value$4 = value.job_id;
        var value$5 = value.hash;
        var value$6 = value.name;
        var value$7 = value.owner;
        return {
                owner: value$7,
                name: value$6,
                hash: value$5,
                job_id: value$4,
                variant: value$3,
                outcome: outcome,
                error: error
              };
      });
  return {
          jobs: jobs
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var GetAllJobs = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

function JobsView$JobRow(Props) {
  var job = Props.job;
  var adminServiceUri = ConfigHooks$WebApp.useAdminServiceUri(undefined);
  var onClick = function (ev) {
    ev.stopPropagation();
    ev.preventDefault();
    var href = adminServiceUri + "/job/" + job.job_id;
    window.open(href);
    
  };
  if (job.variant === "(analysis)") {
    return null;
  } else {
    return React.createElement("tr", {
                className: "cursor-pointer",
                onClick: onClick
              }, React.createElement("td", undefined, job.job_id), React.createElement("td", undefined, job.owner + "/" + job.name), React.createElement("td", undefined, job.hash.substring(0, 8)), React.createElement("td", undefined, job.variant), React.createElement("td", undefined, React.createElement(OutcomeDisplay$WebApp.make, {
                        outcome: job.outcome,
                        err: job.error
                      })));
  }
}

var JobRow = {
  make: JobsView$JobRow
};

function JobsView$Content(Props) {
  var jobs = Props.jobs;
  jobs.sort(function (j1, j2) {
        return -$$String.compare(j1.job_id, j2.job_id) | 0;
      });
  return React.createElement("table", {
              className: "table row-select"
            }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, "Job ID"), React.createElement("th", undefined, "Repo"), React.createElement("th", undefined, "Hash"), React.createElement("th", undefined, "Variant"), React.createElement("th", undefined, "Outcome"))), React.createElement("tbody", undefined, Belt_Array.mapWithIndex(jobs, (function (idx, job) {
                        return React.createElement(JobsView$JobRow, {
                                    job: job,
                                    key: String(idx)
                                  });
                      }))));
}

var Content = {
  make: JobsView$Content
};

function JobsView(Props) {
  var match = RefreshBarWithQuery$WebApp.useWithQuery({
        Raw: Raw,
        query: query,
        parse: parse,
        serializeVariables: serializeVariables,
        variablesToJson: (function (prim) {
            return prim;
          })
      });
  var response = match[0];
  var tmp;
  var exit = 0;
  if (typeof response === "number") {
    tmp = response === /* Fetching */0 ? "Loading" : "Not Found";
  } else {
    switch (response.TAG | 0) {
      case /* Data */0 :
      case /* PartialData */1 :
          exit = 1;
          break;
      case /* Error */2 :
          tmp = response._0.message;
          break;
      
    }
  }
  if (exit === 1) {
    tmp = React.createElement(JobsView$Content, {
          jobs: response._0.jobs
        });
  }
  return React.createElement("main", undefined, React.createElement("div", {
                  className: "p-8"
                }, match[1], tmp));
}

var make = JobsView;

export {
  GetAllJobs ,
  JobRow ,
  Content ,
  make ,
  
}
/* react Not a pure module */
