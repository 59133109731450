// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as String_utils$WebApp from "../../utils/string_utils.bs.js";

function OutcomeDisplay(Props) {
  var outcome = Props.outcome;
  var err = Props.err;
  var filtered_err;
  var exit = 0;
  if (err === "Docker build exited with status 1") {
    filtered_err = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    filtered_err = React.createElement(React.Fragment, undefined, React.createElement("br", undefined), String_utils$WebApp.string_opt(err));
  }
  switch (outcome) {
    case "" :
        return "";
    case "Aborted" :
        return React.createElement(React.Fragment, undefined, "Aborted", filtered_err);
    case "Active" :
        return "Active";
    case "Failed" :
        return React.createElement(React.Fragment, undefined, "Failed ✗", filtered_err);
    case "NotStarted" :
        return "Not started";
    case "Passed" :
        return "✔";
    case "Undefined" :
        return "Undefined";
    default:
      return "Unknown";
  }
}

var make = OutcomeDisplay;

export {
  make ,
  
}
/* react Not a pure module */
