// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Theme$WebApp from "./theme/Theme.bs.js";
import * as Header$WebApp from "./components/navigation/Header.bs.js";
import * as GridView$WebApp from "./views/GridView.bs.js";
import * as JobsView$WebApp from "./views/JobsView.bs.js";
import * as GitHubView$WebApp from "./views/GitHubView.bs.js";
import * as Dayjs_utils$WebApp from "./utils/dayjs_utils.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as MaterialUi_ThemeProvider from "rescript-material-ui/src/MaterialUi_ThemeProvider.bs.js";

var theme = Theme$WebApp.make(undefined);

function App(Props) {
  Dayjs_utils$WebApp.init(undefined);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = url.path;
  var tmp;
  if (match) {
    switch (match.hd) {
      case "github" :
          tmp = match.tl ? null : React.createElement(GitHubView$WebApp.make, {});
          break;
      case "jobs" :
          tmp = match.tl ? null : React.createElement(JobsView$WebApp.make, {});
          break;
      default:
        tmp = null;
    }
  } else {
    tmp = React.createElement(GridView$WebApp.make, {});
  }
  return React.createElement(MaterialUi_ThemeProvider.make, {
              children: null,
              theme: theme
            }, React.createElement(Header$WebApp.make, {}), tmp);
}

var make = App;

export {
  theme ,
  make ,
  
}
/* theme Not a pure module */
