// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Hooks$ReScriptUrql from "@urql/rescript/src/hooks/Hooks.bs.js";

var Raw = {};

var query = "query   {\nconfig  {\nadmin_service_uri  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.config;
  return {
          config: {
            admin_service_uri: value$1.admin_service_uri
          }
        };
}

function serialize(value) {
  var value$1 = value.config;
  var value$2 = value$1.admin_service_uri;
  var config = {
    admin_service_uri: value$2
  };
  return {
          config: config
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var GetAdminServiceUri = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

function useConfig(param) {
  var match = Hooks$ReScriptUrql.useQuery({
        Raw: Raw,
        query: query,
        parse: parse,
        serializeVariables: serializeVariables,
        variablesToJson: (function (prim) {
            return prim;
          })
      }, undefined, undefined, undefined, undefined, "CacheFirst", undefined, undefined, undefined, undefined, undefined);
  var response = match[0].response;
  if (typeof response === "number") {
    return ;
  }
  switch (response.TAG | 0) {
    case /* Data */0 :
    case /* PartialData */1 :
        return response._0.config;
    default:
      return ;
  }
}

function useAdminServiceUri(param) {
  var config = useConfig(undefined);
  return Belt_Option.mapWithDefault(config, "", (function (config) {
                return config.admin_service_uri;
              }));
}

export {
  GetAdminServiceUri ,
  useConfig ,
  useAdminServiceUri ,
  
}
/* Hooks-ReScriptUrql Not a pure module */
