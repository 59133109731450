// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Dayjs from "dayjs";
import * as RelativeTime from "dayjs/plugin/relativeTime";

function init(param) {
  Dayjs.extend(RelativeTime);
  
}

export {
  init ,
  
}
/* dayjs Not a pure module */
