// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core from "@material-ui/core";
import * as IconRefresh$WebApp from "../icons/IconRefresh.bs.js";

function RefreshBar(Props) {
  var lastRefreshed = Props.lastRefreshed;
  var onRefresh = Props.onRefresh;
  return React.createElement("div", {
              className: "refreshBar"
            }, React.createElement("div", undefined, "Last refreshed: ", lastRefreshed.fromNow()), React.createElement(Core.Button, {
                  onClick: onRefresh,
                  children: "Refresh",
                  color: "primary",
                  startIcon: React.createElement(IconRefresh$WebApp.make, {}),
                  variant: "outlined"
                }));
}

var make = RefreshBar;

export {
  make ,
  
}
/* react Not a pure module */
