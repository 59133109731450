// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import Refresh from "@material-ui/icons/Refresh";

var IconRefresh = {};

function IconRefresh$1(Props) {
  return React.createElement(Refresh, {});
}

var make = IconRefresh$1;

export {
  IconRefresh ,
  make ,
  
}
/* react Not a pure module */
