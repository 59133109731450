// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Urql from "urql";
import * as React from "react";
import * as ReactDom from "react-dom";
import * as App$WebApp from "./App.bs.js";
import * as Client$ReScriptUrql from "@urql/rescript/src/Client.bs.js";

import './assets/css/index.css';
;

var client = Client$ReScriptUrql.make(process.env.REACT_APP_GRAPHQL_ENDPOINT, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var root = document.querySelector("#root");

if (!(root == null)) {
  ReactDom.render(React.createElement(Urql.Provider, {
            value: client,
            children: React.createElement(App$WebApp.make, {})
          }), root);
}

export {
  client ,
  
}
/*  Not a pure module */
