// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function getWithDefault(x, k, def) {
  return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(x.get(k)), def);
}

function getBoolean(x, k) {
  var v = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(x.get(k)), "");
  if (v !== "" && v !== "0") {
    return v !== "false";
  } else {
    return false;
  }
}

function setBoolean(x, k, v) {
  if (v) {
    x.set(k, "1");
  } else {
    x.delete(k);
  }
  
}

function toStringQuestionMark(x) {
  var v = x.toString();
  if (v === "") {
    return "";
  } else {
    return "?" + v;
  }
}

export {
  getWithDefault ,
  getBoolean ,
  setBoolean ,
  toStringQuestionMark ,
  
}
/* No side effect */
