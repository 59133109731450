// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Dayjs from "dayjs";
import * as React from "react";
import * as RefreshBar$WebApp from "./RefreshBar.bs.js";
import * as Hooks$ReScriptUrql from "@urql/rescript/src/hooks/Hooks.bs.js";
import * as Interval_utils$WebApp from "../../utils/interval_utils.bs.js";

function useWithQuery(query) {
  var match = React.useState(function () {
        return 1;
      });
  var setEpoch = match[1];
  var match$1 = React.useState(function () {
        return Dayjs();
      });
  var setLastRefreshed = match$1[1];
  var match$2 = Hooks$ReScriptUrql.useQuery(query, undefined, undefined, undefined, undefined, "CacheFirst", undefined, undefined, undefined, undefined, undefined);
  var executeQuery = match$2[1];
  var doRefresh = function (param) {
    Curry.app(executeQuery, [
          undefined,
          undefined,
          undefined,
          "CacheAndNetwork",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        ]);
    return Curry._1(setLastRefreshed, (function (param) {
                  return Dayjs();
                }));
  };
  var onRefresh = function (ev) {
    ev.stopPropagation();
    ev.preventDefault();
    return doRefresh(undefined);
  };
  Interval_utils$WebApp.useInterval((function (param) {
          return Curry._1(setEpoch, (function (epoch) {
                        if (epoch % 10 === 0) {
                          doRefresh(undefined);
                        }
                        return epoch + 1 | 0;
                      }));
        }), 30000);
  return [
          match$2[0].response,
          React.createElement(RefreshBar$WebApp.make, {
                lastRefreshed: match$1[0],
                onRefresh: onRefresh,
                key: match[0].toString()
              })
        ];
}

export {
  useWithQuery ,
  
}
/* dayjs Not a pure module */
