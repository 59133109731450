// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "@material-ui/core/styles";

function make(param) {
  return Styles.createMuiTheme({
              palette: {
                primary: {
                  main: "#404040"
                },
                secondary: {
                  contrastText: "white",
                  main: "rgb(236,125,11)"
                }
              }
            });
}

export {
  make ,
  
}
/* @material-ui/core/styles Not a pure module */
