// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Hooks$ReScriptUrql from "@urql/rescript/src/hooks/Hooks.bs.js";

var Raw = {};

var query = "query   {\norgs  {\nname  \nrepos  {\nname  \nmaster_status  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = value.orgs;
  return {
          orgs: value$1.map(function (value) {
                var value$1 = value.repos;
                return {
                        name: value.name,
                        repos: value$1.map(function (value) {
                              var value$1 = value.master_status;
                              var tmp;
                              switch (value$1) {
                                case "Failed" :
                                    tmp = "Failed";
                                    break;
                                case "NotStarted" :
                                    tmp = "NotStarted";
                                    break;
                                case "Passed" :
                                    tmp = "Passed";
                                    break;
                                case "Pending" :
                                    tmp = "Pending";
                                    break;
                                default:
                                  tmp = {
                                    NAME: "FutureAddedValue",
                                    VAL: value$1
                                  };
                              }
                              return {
                                      name: value.name,
                                      master_status: tmp
                                    };
                            })
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.orgs;
  var orgs = value$1.map(function (value) {
        var value$1 = value.repos;
        var repos = value$1.map(function (value) {
              var value$1 = value.master_status;
              var master_status = typeof value$1 === "string" ? (
                  value$1 === "NotStarted" ? "NotStarted" : (
                      value$1 === "Failed" ? "Failed" : (
                          value$1 === "Passed" ? "Passed" : "Pending"
                        )
                    )
                ) : value$1.VAL;
              var value$2 = value.name;
              return {
                      name: value$2,
                      master_status: master_status
                    };
            });
        var value$2 = value.name;
        return {
                name: value$2,
                repos: repos
              };
      });
  return {
          orgs: orgs
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var GetAllOrgs = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

function GitHubView$RepoDisplay(Props) {
  var org = Props.org;
  var repo = Props.repo;
  return React.createElement("li", undefined, React.createElement("a", {
                  href: "/github/" + org.name + "/" + repo.name,
                  rel: "noopener noreferrer",
                  target: "_blank"
                }, repo.name));
}

var RepoDisplay = {
  make: GitHubView$RepoDisplay
};

function GitHubView$OrgDisplay(Props) {
  var org = Props.org;
  var repos = org.repos;
  repos.sort(function (o1, o2) {
        return $$String.compare(o1.name, o2.name);
      });
  return React.createElement("li", undefined, org.name, React.createElement("ul", undefined, Belt_Array.map(repos, (function (repo) {
                        return React.createElement(GitHubView$RepoDisplay, {
                                    org: org,
                                    repo: repo,
                                    key: repo.name
                                  });
                      }))));
}

var OrgDisplay = {
  make: GitHubView$OrgDisplay
};

function GitHubView$OrgList(Props) {
  var orgs = Props.orgs;
  orgs.sort(function (o1, o2) {
        return $$String.compare(o1.name, o2.name);
      });
  return React.createElement("ul", {
              className: "org-list"
            }, Belt_Array.map(orgs, (function (org) {
                    return React.createElement(GitHubView$OrgDisplay, {
                                org: org,
                                key: org.name
                              });
                  })));
}

var OrgList = {
  make: GitHubView$OrgList
};

function GitHubView(Props) {
  var match = Hooks$ReScriptUrql.useQuery({
        Raw: Raw,
        query: query,
        parse: parse,
        serializeVariables: serializeVariables,
        variablesToJson: (function (prim) {
            return prim;
          })
      }, undefined, undefined, undefined, undefined, "CacheFirst", undefined, undefined, undefined, undefined, undefined);
  var response = match[0].response;
  var tmp;
  var exit = 0;
  if (typeof response === "number") {
    tmp = response === /* Fetching */0 ? "Loading..." : "Not Found";
  } else {
    switch (response.TAG | 0) {
      case /* Data */0 :
      case /* PartialData */1 :
          exit = 1;
          break;
      case /* Error */2 :
          tmp = response._0.message;
          break;
      
    }
  }
  if (exit === 1) {
    tmp = React.createElement(GitHubView$OrgList, {
          orgs: response._0.orgs
        });
  }
  return React.createElement("main", {
              className: "p-8"
            }, React.createElement("h1", undefined, "GitHub integration"), React.createElement("p", undefined, "See ", React.createElement("a", {
                      href: "https://github.com/apps/multicore-ci"
                    }, "The OCaml-Multicore-CI GitHub App"), " for details."), React.createElement("div", undefined, React.createElement("h2", undefined, "Registered repos"), tmp));
}

var make = GitHubView;

export {
  GetAllOrgs ,
  RepoDisplay ,
  OrgDisplay ,
  OrgList ,
  make ,
  
}
/* react Not a pure module */
