// Generated by ReScript, PLEASE EDIT WITH CARE


function string_opt(str) {
  if (str !== undefined) {
    return str;
  } else {
    return "";
  }
}

export {
  string_opt ,
  
}
/* No side effect */
